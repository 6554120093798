<template>
  <layout :menu-tree="menuTree"></layout>
</template>

<script>
import Layout from "@/views/front/jsf35/operations";
import {reactive, toRefs} from "@vue/composition-api";

export default {
  name: "EngineeringCenter",
  components: {
    Layout,
  },
  setup() {
    const event = reactive({
      //默认加载的组件

      menuTree: [
        /* {
title: "综合概况",
component_key: "alarm-processing",
}, */
        {
          title: "报警信息管理",
          children: [
            {
              title: "安防报警列表",
              iframe: "app.dreamsfount35.com/ems/securityAlarmHistoryLog/index",
            },
            {
              title: "消防报警列表",
              iframe: "app.dreamsfount35.com/ems/fireAlarmHistoryLog/index",
            },
          ],
        },

        {
          title: "人员布控管理",
          href: "http://192.168.6.239:8802"
        },

        {
          title: "门禁管理",
          children: [
            {
              title: "门禁设置",
              iframe: "v4.qiyebox.com/#/m/door/list",
            },
            {
              title: "通行记录",
              iframe: "v4.qiyebox.com/#/m/door/record",
            },
            {
              title: "人脸设置",
              iframe: "v4.qiyebox.com/#/m/door/face",
            },
            {
              title: "人脸下发记录",
              iframe: "v4.qiyebox.com/#/m/door/face/record",
            },
            {
              title: "健康宝日志",
              iframe: "v4.qiyebox.com/#/m/door/health_log",
            },
            {
              title: "闸机管理",
              iframe: "v4.qiyebox.com/#/m/door/gate",
            },
          ],
        },
        {
          title: "应急预案管理",
          iframe: "app.dreamsfount35.com/ems/material/index",
        },
      ],
    });

    return {
      ...toRefs(event),
    };
  },
};
</script>

<style lang="scss" scoped></style>
